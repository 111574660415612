<div class="row">
    <div *ngIf="!isFromDetails" class="col-sm-12 col-md-3 container-dates vertical-align">
        <button class="btn-close-icon" (click)="$event.stopPropagation();close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
        <h2 class="trip-name">{{tripName.toUpperCase()}}</h2>
        <h5>{{'FROMFLYINFO' | translate }} {{trip.start_date | date: 'dd/MMM':"UTC"}} {{'TOFLYINFO' | translate }}
            {{trip.end_date | date: 'dd/MMM':"UTC"}}</h5>
        <h4 class="text-orange">{{'FROM' | translate }}</h4>
        <div class="visiting-container">
            <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
            <h5>{{ slices[0].origin.city_name}}</h5>
        </div>
        <h4 class="text-orange visiting">{{'VISITING' | translate }}</h4>
        <ng-container *ngFor="let city of trip.visiting;">
            <div class="visiting-container">
                <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                <h5>{{city}}</h5>
            </div>

        </ng-container>
    </div>
    <div *ngIf="isFromDetails" [ngClass]="{'inline-block': isFromDetails}" class="col-12 vertical-align">
        <h2 class="trip-name">{{tripName.toUpperCase()}}</h2>
        <strong class="amount-header mt-2">{{'TOTALPRICE' | translate }}: ${{amount | number : '1.2-2'}} <span
                class="currency">MXN</span></strong><br>
        <div class="coupon">
            <p class="gray-text">{{'COUPON' | translate }}</p>
            <input [formControl]="coupon" type="text">
            <a mat-button class="orange-text" (click)="apply()">{{'APPLY' | translate }}</a>
        </div>
        <div [ngClass]="{'margin-top-1': isFromDetails}" class="row">
            <div class="col-4">
                <h4 class="text-orange">{{'FROM' | translate }}</h4>
                <div class="visiting-container">
                    <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                    <h5>{{ slices[0].origin.city_name }}</h5>
                </div>
            </div>
            <div class="col-4">
                <h4 class="text-orange">{{'VISITING' | translate }}</h4>
                <ng-container *ngFor="let city of trip.visiting;">
                    <div class="visiting-container">
                        <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                        <h5>{{city}}</h5>
                    </div>

                </ng-container>
            </div>
            <div class="col-4 vertical-align dates-info">
                <h5 [ngClass]="{'orange-color' : isFromDetails}">{{'FROMFLYINFO' | translate }} {{trip.start_date |
                    date: 'dd/MMM'}} {{'TOFLYINFO' | translate }}
                    {{trip.end_date | date: 'dd/MMM'}}</h5>
            </div>
        </div>
    </div>
    <div [ngClass]="{'inline-block': isFromDetails, 'margin-top-1' : isFromDetails}" class="col-sm-12 col-md-9">
        <div class="row second-row">
            <div class="col-sm-12 text-center col-md-6">
              <h5>{{'INCLUDES_FLIGHTS' | translate }}: {{trip.flights_included}} {{(trip.flights_included === 1 ? 'FLIGHT' : 'FLIGHTS') | translate }} {{'INCLUDES_TRAINS' | translate }} {{trip.trains_included}} {{(trip.flights_included === 1 ? 'TRAIN' : 'TRAINS') | translate }}</h5>
                <p>{{trip.description}}</p>
            </div>
            <div class="col-sm-12 col-md-6 text-center trip-info-icons">
                <div class="row nights-info">
                    <div class="col-md-6">
                        <div>
                            <strong>{{'BAGSINCLUDED' | translate }}:</strong>
                            <div>
                                <img class="icon mr-2" src="../../../../../assets/img/luggage-01.png">
                                <span class="mr-2">{{trip.carry_on_luggage_included}}</span>
                                <img class="icon mr-2" src="../../../../assets/img/luggage-02.png">
                                <span>{{trip.checked_luggage_included}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <strong>{{'CABINCLASS' | translate }}</strong>
                        <h6>{{'ECONOMY' | translate }}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-fly mt-2">
            <app-fly-information-card [slices]="slices"></app-fly-information-card>
        </div>
    </div>
</div>
